//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
  .logo {
    img {
      width: 25rem;
    }
  }
}

/*=============================
// Header
=============================*/
.site-header,
.modal-header {
  .logo {
    img {
      width: 12.5rem;
    }
  }
}

/*=============================
// Footer
=============================*/
.site-footer {
  .logo {
    img {
      width: 10.5rem;
    }
  }
}
